import { Button, Typography, Select, MenuItem } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import React, {useMemo} from 'react';
import {useGetTaxYears} from "../../../../../hooks/useGetTaxYears";
import Skeleton from "../../../../Skeleton";
import {FreepayrollSelect} from "@collegia-partners/ui-kit";
import {parseISO} from "date-fns";
import format from "date-fns/format";

const InnerStepOne = ({ stepZero, setStepZero, basicSetup = () => {} }) => {

	const { years, isPending } = useGetTaxYears();

	const startYear = useMemo(() => {
		return years?.find(year => String(year.id) === stepZero.starting_tax_year);
	}, [years, stepZero.starting_tax_year]);

	return (
		<div id={'inner-step-one'} style={{ display: 'flex', flexWrap: 'wrap' }}>
			<div className={'body-title-container'} style={{ width: '100%' }}>
				<Typography className={'body-title'}>
					Customising your setup experience
				</Typography>
			</div>

			{
				isPending ? (
					<div style={{ display: 'flex', width: '100%', marginTop: '20px' }} >
						<Skeleton width={'100%'} height={200} background={'#D6D8E1'}/>
					</div>
				) : (
					<div>
						<div className={'body-sub-container'} style={{ width: '100%' }}>
							<Typography className={'body-subtitle-1'}>
								Select the tax year in which your first payroll date with FreePayroll will take place
							</Typography>

							<div style={{ width: '40%' }}>

								<FreepayrollSelect
									options={years?.map((year) => ({
										value: String(year.id),
										label: format(parseISO(year.from),'dd MMM yy') + ' - ' + format(parseISO(year.to),'dd MMM yy'),
									}))}
									onSelect={(option) => {
										setStepZero({
											...stepZero,
											starting_tax_year: option.value,
										});
									}}
									size="medium"
								/>
							</div>
						</div>

						<div
							className={'body-sub-container-2'}
							style={{ flexBasis: '100%', maxWidth: '100%' }}
						>
							<Typography className={'body-subtitle-1'}>
								Will this be your company’s first pay day since 6 April {startYear?.from?.slice(0,4)}?
							</Typography>
							<RadioGroup
								row
								onChange={(event) => {
									setStepZero({
										...stepZero,
										first_tax_year_pay: event.target.value === 'true',
										first_payroll_ever: null,
										current_payroll_provider: ' ',
									});
								}}
							>
								<FormControlLabel value={true} control={<Radio />} label="Yes" />
								<FormControlLabel value={false} control={<Radio />} label="No" />
							</RadioGroup>
						</div>

						{stepZero.first_tax_year_pay === true && (
							<div
								className={'body-sub-container-3'}
								style={{ flexBasis: '100%', maxWidth: '100%' }}
							>
								<Typography className={'body-subtitle-1'}>
									Will this be the first payroll ever for this company?
								</Typography>
								<RadioGroup
									row
									onChange={(event) => {
										setStepZero({
											...stepZero,
											first_payroll_ever: event.target.value === 'true',
											current_payroll_provider: ' ',
										});
									}}
								>
									<FormControlLabel value={true} control={<Radio />} label="Yes" />
									<FormControlLabel value={false} control={<Radio />} label="No" />
								</RadioGroup>
							</div>
						)}

						{(stepZero.first_tax_year_pay === false ||
							(stepZero.first_tax_year_pay === true &&
								stepZero.first_payroll_ever === false)) && (
							<div className={'body-sub-container-4'} style={{ width: '40%' }}>
								<Typography className={'body-subtitle-1'}>
									Who is your current payroll provider?
								</Typography>
								<FormControl fullWidth>
									<Select
										className={'default-select'}
										id={'select-current-provider'}
										value={stepZero.current_payroll_provider}
										onChange={(event) => {
											setStepZero({
												...stepZero,
												current_payroll_provider: event.target.value,
											});
										}}
									>
										<MenuItem value={' '}>Select current provider...</MenuItem>
										<MenuItem value={'BrightPay'}>BrightPay</MenuItem>
										<MenuItem value={'Sage'}>Sage</MenuItem>
										<MenuItem value={'Iris'}>Iris</MenuItem>
										<MenuItem value={'MoneySoft'}>MoneySoft</MenuItem>
										<MenuItem value={'Basic PAYE Tools'}>Basic PAYE Tools</MenuItem>
										<MenuItem value={'Other'}>Other</MenuItem>
									</Select>
								</FormControl>
							</div>
						)}

						<div
							className={'button-container'}
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								flexBasis: '100%',
								maxWidth: '100%',
							}}
						>
							<Button
								className={'default-black-button'}
								onClick={() =>
									basicSetup({
										employers: {
											step: 'payroll_dates',
											data_insert: {
												starting_tax_year_id: `${stepZero.starting_tax_year}`,
												first_pay_in_tax_year: stepZero.first_tax_year_pay,
												first_payroll_ever:
													stepZero.first_payroll_ever === null
														? false
														: stepZero.first_payroll_ever,
												current_payroll_provider: stepZero.current_payroll_provider,
												company_planning_pay: stepZero.myself
													? 'myself '
													: '' + stepZero.employees
														? 'employees '
														: '' + stepZero.not_paying
															? 'not_paying'
															: '',
											},
										},
									})
								}
								disabled={
									!stepZero.starting_tax_year ||
									stepZero.first_tax_year_pay === null ||
									(stepZero.first_tax_year_pay === false &&
										stepZero.current_payroll_provider === ' ') ||
									(stepZero.first_tax_year_pay === true &&
										stepZero.first_payroll_ever === false &&
										stepZero.current_payroll_provider === ' ') ||
									(stepZero.first_tax_year_pay === true &&
										stepZero.first_payroll_ever === null)
								}
							>
								Submit
							</Button>
						</div>
					</div>
				)
			}
		</div>
	);
};

export default InnerStepOne;
