import React, {useEffect} from "react";
import Warning from "../../../../images/svg/ReportsIcon/p60-warning.svg";
import DownloadIcon from "../../../../images/svg/ReportsIcon/download-icon.svg";
import {Typography, Divider} from "@collegia-partners/ui-kit";
import {useGetP60Years} from "../../../../hooks/employer/useGetP60Years";
import {useDownloadAllP60} from "../../../../hooks/employer/useDownloadAllP60";
import {useLoggedLayout} from "../../../../context/LoggedLayoutContext";
import Skeleton from "../../../../components/Skeleton";

function RenderGrids({
	index = 0,
	year = "",
}) {

	const { mutate: downloadP60, isPending } = useDownloadAllP60();

	return (
		<div className={"reports-p60-map-row"}>
			<Typography
				variant={"body"}
				color={"black"}
				weight={index === 0 ? "bold" : ""}
			>
				{year}
			</Typography>
			<div
				className={"reports-p60-map-download-row"}
				onClick={() => {
					if (isPending) return;
					downloadP60(index);
				}}
			>
				<Typography
					variant={"body"}
					color={"black"}
					weight={"bold"}
				>
					{ isPending ? "Downloading..." : "Download" }
				</Typography>
				<img
					className={"img"}
					src={DownloadIcon}
					alt={"Download"}
				/>
			</div>
		</div>
	)
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ReportsP60 = (): JSX.Element => {

	const {setLayoutProps} = useLoggedLayout();
	const { years, isPending: isLoadingYears } = useGetP60Years();

	useEffect(() => {
		setLayoutProps({
			activePage: 'Reports',
			showBackButton: true,
			backLink: '/main/reports',
			expanded: false
		});
	}, [setLayoutProps]);

	return (
		<div
			id={"reports-p60"}
		>
			<div
				className={"reports-p60-item"}
			>
				<Typography
					variant={"title"}
					weight={"bold"}
					color={"#0160FD"}
				>
					P60s
				</Typography>
				<Typography
					variant={"subtitle2"}
					weight={"normal"}
					color={"gray"}
				>
					All employees must be given a P60 at the end of each tax year. It details the tax that <br/>
					has been paid by an employee on their earnings.
				</Typography>
				<div className={"reports-title-margin"}>
					<Divider color={"light-gray"}/>
				</div>
				<div
					className={"reports-p60-description"}
				>
					<img
						className={"reports-p60-description-warning-image"}
						src={Warning}
						alt={"Warning"}
					/>
					<Typography
						color={"black"}
						variant={"subtitle2"}
						weight={"bold"}
					>
						Your P60s will be automatically emailed to your employees, when you close your tax year.
					</Typography>
				</div>
				<div
					className={"reports-p60-download-title"}
				>
					<Typography
						color={"black"}
						weight={"bold"}
						variant={"subtitle"}
					>
						Download
					</Typography>
				</div>
				<div
					className={"reports-p60-download-sub-title"}
				>
					<Typography
						color={"gray"}
						weight={"normal"}
						variant={"body"}
					>
						You can download individual P60 documents by visiting individual employee profiles.
					</Typography>
				</div>
				{
					years?.length > 0 && isLoadingYears === false &&
					<div
						className={"reports-p60-item-map"}
					>
						{
							years?.map((yearItem, index) => {
								return (
									<div
										key={yearItem?.tax_year_id}
									>
										<RenderGrids
											year={yearItem?.tax_year?.formated_tax_year}
											index={yearItem?.tax_year_id}
										/>
										{
											years?.length-1 !== index
												? <div
													className={"reports-p60-item-map-divider"}
												>
													<Divider color={"light-gray"}/>
												</div>
												: ""
										}
									</div>
								)
							})
						}
					</div>
				}
				{
					isLoadingYears &&
						<Skeleton
							height={200}
							width={"50%"}
							background={"#D6D8E1"}
						/>
				}
			</div>
		</div>
	);
}

export default ReportsP60;