import {useNavigate} from "react-router-dom";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import Request from "../../utils/Request";
import {toast} from "sonner";

export function useReopenPayroll() {

	const navigate = useNavigate();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (id) => {
			return await Request.post(`/api/employers/payrolls/${id}/reopen`)
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			if (data?.success) {
				navigate('/main');
				queryClient.invalidateQueries({queryKey: ['payrolls']});
				toast.success("Payroll reopened successfully");
			}
			return data;
		},
	});
}