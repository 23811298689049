import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import PeopleGray from '../images/svg/MenuIcons/people-gray.svg';
import PeopleBlue from '../images/svg/MenuIcons/people-blue.svg';
import BuildingGray from '../images/svg/MenuIcons/building-gray.svg';
import BuildingBlue from '../images/svg/MenuIcons/building-blue.svg';
import LeafGray from '../images/svg/MenuIcons/leaf-gray.svg';
import LeafBlue from '../images/svg/MenuIcons/leaf-blue.svg';
import SquareGray from '../images/svg/MenuIcons/square-gray.svg';
import SquareBlue from '../images/svg/MenuIcons/square-blue.svg';
import HomeBlue from '../images/svg/MenuIcons/home-blue.svg';
import HomeGray from '../images/svg/MenuIcons/home-gray.svg';
import VideoBlue from '../images/svg/MenuIcons/video-blue.svg';
import VideoGray from '../images/svg/MenuIcons/video-gray.svg';
import WalletBlue from '../images/svg/MenuIcons/wallet-blue.svg';
import ReportsBlue from '../images/svg/MenuIcons/reports-blue.svg';
import ReportsGray from '../images/svg/MenuIcons/reports-gray.svg';
import WalletGray from '../images/svg/MenuIcons/wallet-gray.svg';
import React, { useEffect, useState } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import { useUser } from '../context/UserContext';

function renderMenuItem(
	expanded,
	itemName,
	activeItem,
	activeImage,
	inactiveImage,
	link,
	navigate,
	isOnboarding,
	lockMenuItems,
	lockAllMenuItems,
) {
	const cursor =
		(isOnboarding && itemName !== 'Setup') ||
		lockMenuItems.includes(itemName) ||
		lockAllMenuItems
			? 'not-allowed !important'
			: 'pointer';

	return (
		<div className={'grid-texts'}>
			<img
				src={itemName === activeItem ? activeImage : inactiveImage}
				alt={'logo'}
				className={
					'img' +
					(lockMenuItems.includes(itemName) || lockAllMenuItems ? ' lock' : '')
				}
			/>
			{expanded && (
				<Typography
					className={
						itemName === activeItem ? 'menu-options-active' : 'menu-options'
					}
					onClick={() => {
						if (
							!isOnboarding &&
							!lockMenuItems.includes(itemName)
						) {
							if (lockAllMenuItems) return;
							navigate(link);
						}
					}}
					sx={{
						cursor: cursor,
						opacity:
							lockMenuItems.includes(itemName) || lockAllMenuItems
								? '0.3 !important'
								: '1 !important',
						fontWeight:
							lockMenuItems.includes(itemName) || lockAllMenuItems
								? 'normal'
								: 'bold',
					}}
				>
					{itemName}
				</Typography>
			)}
		</div>
	);
}

function renderMenuItemWithSubItem(
	expanded,
	itemName,
	activeItem,
	activeImage,
	inactiveImage,
	subItemArray,
	activeSubsection,
	navigate,
	isOnboarding,
	lockMenuItems,
	expandAccordion = false,
	lockAllMenuItems,
) {
	return (
		<div>
			<Accordion
				className={'menu-accordion-style'}
				disableGutters
				elevation={0}
				sx={{
					'&:before': {
						display: 'none',
					},
					cursor:
						isOnboarding || lockMenuItems.includes(itemName) || lockAllMenuItems
							? 'not-allowed !important'
							: 'pointer',
				}}
				disabled={
					isOnboarding || lockMenuItems.includes(itemName) || lockAllMenuItems
				}
				defaultExpanded={expandAccordion}
			>
				<AccordionSummary expandIcon={expanded && <ExpandMoreIcon />}>
					<div className={'grid-texts'}>
						<img
							src={itemName === activeItem ? activeImage : inactiveImage}
							alt={'logo'}
							className={'img'}
						/>
						{expanded && (
							<Typography
								className={
									itemName === activeItem
										? 'menu-options-active'
										: 'menu-options'
								}
								sx={{
									fontWeight: lockMenuItems.includes(itemName)
										? 'normal'
										: 'bold',
								}}
							>
								{itemName}
							</Typography>
						)}
					</div>
				</AccordionSummary>
				{expanded && (
					<AccordionDetails
						sx={{
							paddingLeft: expanded ? '1.0677vw' : '0px',
							marginLeft: expanded ? '12px' : '0px',
						}}
					>
						<div>
							{subItemArray.map((item, index) => {
								return (
									<Typography
										key={index}
										className={
											activeSubsection === item.name
												? 'sub-menu-options-active'
												: 'sub-menu-options'
										}
										onClick={() => {
											if (!isOnboarding) {
												navigate(item.link);
											}
										}}
									>
										{item.name}
									</Typography>
								);
							})}
						</div>
					</AccordionDetails>
				)}
			</Accordion>
		</div>
	);
}

export const LeftMenu = ({
	expanded = true,
	activePage = 'Home',
	activeSubpage = '',
	isOnboarding = false,
	lockMenuItems = [],
	isFixed = false,
}) => {
	const { profileData: user } = useUser();

	const navigate = useNavigate();
	const location = useLocation();

	const [menuExpanded, setMenuExpanded] = useState(expanded);
	const payrollSubItems = [
		{
			name: 'Active',
			link: '/main/payroll/payroll-active',
		},
		{
			name: 'History',
			link: '/main/payroll/payroll-history',
		},
		{
			name: 'Archived',
			link: '/main/payroll/payroll-archived',
		},
	];
	const companySubItems = [
		{
			name: 'Details',
			link: '/main/company/details',
		},
		{
			name: 'Allowances',
			link: '/main/company/allowances',
		},
		{
			name: 'Pay Items',
			link: '/main/company/pay-items',
		},
		{
			name: 'Pay Schedules',
			link: '/main/company/pay-schedules',
		},
		{
			name: 'Statutory',
			link: '/main/company/statutory',
		},
		{
			name: 'Salaries',
			link: '/main/company/salaries',
		},
	];

	const lockAllMenuItems = user?.account_locked;

	useEffect(() => {
		if (expanded) {
			setMenuExpanded(true);
		} else {
			setMenuExpanded(false);
		}
	}, [expanded]);

	return (
		<div
			id={'menu-component'}
			className={menuExpanded ? 'expanded' : ''}
			style={isFixed ? { position: 'fixed' } : null}
			onMouseEnter={() => {
				if (!expanded) {
					setMenuExpanded(true);
				}
			}}
			onMouseLeave={() => {
				if (!expanded) {
					setMenuExpanded(false);
				}
			}}
		>
			{renderMenuItem(
				menuExpanded,
				isOnboarding ? 'Setup' : 'Home',
				activePage,
				HomeBlue,
				HomeGray,
				'/main',
				navigate,
				isOnboarding,
				lockMenuItems,
				lockAllMenuItems,
			)}

			{renderMenuItemWithSubItem(
				menuExpanded,
				'Payroll',
				activePage,
				WalletBlue,
				WalletGray,
				payrollSubItems,
				activeSubpage,
				navigate,
				isOnboarding,
				lockMenuItems,
				location.pathname.includes('/main/payroll'),
				lockAllMenuItems,
			)}

			{renderMenuItemWithSubItem(
				menuExpanded,
				'Company',
				activePage,
				BuildingBlue,
				BuildingGray,
				companySubItems,
				activeSubpage,
				navigate,
				isOnboarding,
				lockMenuItems,
				location.pathname.includes('/main/company'),
				lockAllMenuItems,
			)}

			{renderMenuItem(
				menuExpanded,
				'HMRC',
				activePage,
				SquareBlue,
				SquareGray,
				'/main/hmrc-settings',
				navigate,
				isOnboarding,
				lockMenuItems,
				lockAllMenuItems,
			)}

			{renderMenuItem(
				menuExpanded,
				'Auto Enrolment',
				activePage,
				LeafBlue,
				LeafGray,
				'/main/auto-enrolment-menu',
				navigate,
				isOnboarding,
				lockMenuItems,
				lockAllMenuItems,
			)}

			{renderMenuItem(
				menuExpanded,
				'People',
				activePage,
				PeopleBlue,
				PeopleGray,
				'/main/people/team-members',
				navigate,
				isOnboarding,
				lockMenuItems,
				lockAllMenuItems,
			)}

			{renderMenuItem(
				menuExpanded,
				'Reports',
				activePage,
				ReportsBlue,
				ReportsGray,
				'/main/reports',
				navigate,
				isOnboarding,
				lockMenuItems,
				lockAllMenuItems,
			)}

			{renderMenuItem(
				menuExpanded,
				'Tutorials',
				activePage,
				VideoBlue,
				VideoGray,
				'/main/tutorials',
				navigate,
				isOnboarding,
				[],
				lockAllMenuItems,
			)}
		</div>
	);
};
