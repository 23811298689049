import {
	keepPreviousData,
	useMutation,
	useQuery, useQueryClient,
} from '@tanstack/react-query';
import Request from "../../utils/Request";
import {toast} from "sonner";

export function useGetEpsReport({taxYearId, page, orderBy, orderDir}) {
	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['report_eps', {taxYearId, page, orderBy, orderDir}],
		queryFn: async () => {
			return await Request.get(`/api/employers/reports/eps`,
				{
					params: {
						taxYearId,
						page,
						orderBy,
						orderDir
					}
				}).then(response => response?.data ?? {});
		},
		placeholderData: keepPreviousData,
		staleTime: 1000 * 60 * 5, // 5 minutes
	});
	return {
		report_eps: data?.data,
		isPending,
		isError,
	};
}

export function useDownloadEPS() {

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post(`/api/employers/download-eps`, data, { responseType: 'blob' })
				.then(response => response.data);
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			const url = window.URL.createObjectURL(new Blob([data], { type: 'application/zip' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'eps_file');
			document.body.appendChild(link);
			link.click();
		},
	});
}

export function useSubmitEPS() {

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data) => {
			return await Request.post('/api/employers/submit-manually-eps', data)
				.then(response => response.data);
		},
		onError: (error) => {
			toast.error('An error occurred');
			return error;
		},
		onSuccess: () => {
			queryClient.invalidateQueries({queryKey: ['report_eps']});
			toast.success('Eps submitted successfully');
		},
	});
}