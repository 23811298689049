import {useQuery} from "@tanstack/react-query";
import Request from "../../utils/Request";

export function useGetP60Years() {

	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['p60_years'],
		queryFn: async () => {
			return await Request.get(`/api/employers/p60/get-years`)
				.then(response => response.data)
		},
		keepPreviousData: true,
	});

	return {
		years: data?.data,
		isPending,
		isError,
	};
}