import {useQuery} from "@tanstack/react-query";
import Request from "../utils/Request";

export function useGetTutorials() {

	const {
		data,
		isPending,
		isError,
	} = useQuery({
		queryKey: ['tutorials'],
		queryFn: async () => {
			return await Request.get(`/api/tutorials`)
				.then(response => response.data)
		},
		staleTime: 1000 * 60 * 120, // 2 hour
		keepPreviousData: true,
	});

	return {
		tutorials: data?.data,
		isPending,
		isError,
	};
}