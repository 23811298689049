import {useMutation} from "@tanstack/react-query";
import Request from "../../utils/Request";

export function useDownloadAllP60() {

	return useMutation({
		mutationFn: async (taxYearId) => {
			return await Request.get(`/api/employers/p60/download-all?tax_year_id=${taxYearId}`,{ responseType: 'blob' })
				.then(response => {
					return response;
				});
		},
		onError: (error) => {
			return error;
		},
		onSuccess: (data) => {
			const url = window.URL.createObjectURL(new Blob([data.data], { type: 'application/zip' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'p60s.zip');
			document.body.appendChild(link);
			link.click();
		},
	});
}